import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Fade from "react-reveal/Fade";
import CloseIcon from "@material-ui/icons/Close";

const NavbarContainera = styled.div`
  width: 100%;
  height: 80px;
  z-index: 1;
`;

const NavbarWrap = styled.div`
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.div`
  width: 130px;
  height: 120px;
  background: url("") 0 45% no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-top: 80px;
`;

const Nav = styled.nav`
  flex: 1;
  position: relative;
  padding-left: 50px;
`;
const NavLink = styled(Link)`
  color: #fff;
  padding: 0 15px;
  font-size: 13px;
  line-height: 80px;
  font-weight: 700;
  text-decoration: none;
  justify-content: end;
  &:hover {
    color: #f5f51b;
    font-size: 16px;
  }
`;
const Search = styled.div`
  right: 1px;
  position: absolute;
`;
const SearchWrap = styled.div`
  width: 230px;
  height: 36px;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  line-height: 33px;
  padding-left: 37px;
  border-radius: 19px;
  color: #fff;
`;
const Input = styled.input`
  color: #000;
  width: 180px;
  border: orange;
  font-size: 14px;
  background: #fff;
  outline: none;

  &::placeholder {
    color: #000;
  }
`;

const RightMenu = styled.div`
text-transform: uppercase;
display: flex;
align-items: center;
flex-direction: column;
align-items: start;
margin-bottom: 0px;
margin-Top: 0px;
margin-left: 10px;
padding: 20px;
position: fixed;
background-color: black;
transform: ${(props) => (props.show ? "translateX(0)" : "translateX(500%)")};
transition: transform 0.2s;
width: 250px;
height: 250px;
z-index: 16;
`;

const NavLink2 = styled(Link)`
  margin-right: 10px;
  padding: 2px;
  color: #fff;
  flex-wrap: nowrap;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: #f5f51b;
    font-size: 17px;
  }
`;
const CustomMenu = styled(MenuIcon)`
  padding: 20px;
  scale: 200%;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #f5f51b;
    transform: scale(120%);
  };
  
`;

const CustomClose = styled(CloseIcon)`
  color: #f5ed07;
  cursor: pointer;
`;

const CloseWraper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 230px;
`;

const Mobile = styled.div`
@media (min-width: 768px) {
    display: none;
  };
`;

const NavbarT = () => {
  const [burgerStatus, setBurgerStatus] = useState(false);

  return (
    <div>
      <NavbarContainera>
        <NavbarWrap>
          <Logo />
          <Nav>
            <NavLink to="/">HOME</NavLink>
            <NavLink to="/About">ABOUT US</NavLink>
            <NavLink to="/Company">COMPANY INFO</NavLink>
            <NavLink to="/Contact">CONTACT US</NavLink>
            <NavLink to="/Services">OUR SERVICES AND PRODUCT</NavLink>
            <NavLink to="/Success">KEY SUCCESS</NavLink>
            <NavLink to="/News">NEWS</NavLink>
            <NavLink to="/Management">MANAGEMENT</NavLink>
            <NavLink to="/Gallery">GALLERY</NavLink>
          </Nav>
          <Search></Search>
        </NavbarWrap>
        <Mobile>
        <Fade left>
          <CustomMenu onClick={() => setBurgerStatus(true)} />

          <RightMenu show={burgerStatus}>
            <CloseWraper>
              <CustomClose onClick={() => setBurgerStatus(false)} />
            </CloseWraper>
            <NavLink2 to="/">. HOME</NavLink2>
            <NavLink2 to="/About">. ABOUT US</NavLink2>
            <NavLink2 to="/Company">. COMPANY INFO</NavLink2>
            <NavLink2 to="/Contact">. CONTACT US</NavLink2>
            <NavLink2 to="/Services">. OUR SERVICES AND PRODUCT</NavLink2>
            <NavLink2 to="/Success">. KEY SUCCESS</NavLink2>
            <NavLink2 to="/News">. NEWS</NavLink2>
            <NavLink2 to="/Management">. MANAGEMENT</NavLink2>
          </RightMenu>
        </Fade>
        </Mobile>
      </NavbarContainera>
    </div>
  );
};

export default NavbarT;
