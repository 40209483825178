import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import News from './components/News';
import Services from './components/Services';
import Management from './components/Management';
import Company from './components/Company';
import Success from './components/Success';
import Gallery from './components/Gallery';
import SimpleReactLightbox from 'simple-react-lightbox';


ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
    <Router>
    
    <Switch>
    <Route path="/Management">
            <Management />
          </Route>
          <Route path="/Company">
            <Company />
          </Route>
          <Route path="/Success">
            <Success />
          </Route>
          <Route path="/About">
            <About />
          </Route>
          <Route path="/Contact">
            <Contact />
          </Route>
          <Route path="/News">
            <News />
          </Route>
          <Route path="/Services">
            <Services />
          </Route>
          <Route path="/Gallery">
            <Gallery />
          </Route>
          <Route exact path="/">
            <App />
          </Route>
        </Switch>    
    </Router>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
