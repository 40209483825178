import React from 'react';
import Navbar from './Navbar';
import styled from 'styled-components';
import Footer from './Footer/index';
import Footer1 from './Footer1';

const MainContent = styled.div`
background-color: #f7f8fa;

`;
const Subcontent = styled.div`
display: grid;
grid-template-columns: 50% 50%;
gap: 1rem;
`;

const Sidebar = styled.div`
background-color: #fffdf5;
`;
const Wrapper = styled.div`
margin:1rem auto;
width: min(90%, 75rem);
display: grid;
grid-template-columns: 66% 33%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;


const Success = () => {
    return (
        <div>
            <div className="App"
      style={{
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(https://res.cloudinary.com/tambogift/image/upload/v1629483388/Fancydone_1_1920x540_wwqvqm.png)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
            <div><Navbar/></div>
       </div>    
        <Wrapper>               
        <MainContent>
        <p><h3><center>****Key Success****</center></h3></p>

        <p><h3>KEY TO SUCCESS</h3>
<p>Currently, with the high demand for Civil Engineering services, Mechanical Engineering services, Construction services and other sectors, we, NSOMBO INVESTMENTS therefore aim for excellence in fulfilling our promise of credible service delivery, thereby developing undoubted confidence in our clients, we provide easy access for our clients and great visibility for potential clients. With valuable experience in the field, we provide customer satisfaction and develop loyalty in our clients by providing reasonable pricing, free expert advice , fast and detailed quotations and the ability to handle and resolve queries.
</p>
</p>

<p></p>


<Subcontent>
<div>


</div>
</Subcontent>
<p></p>
<hr/>

</MainContent>
        <Sidebar>
        <div>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290458/nsombo/g4_fq2gdf.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290460/nsombo/g12_etixpa.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067187/nsombo/f3_w5bq3q.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290464/nsombo/g7_uh8eia.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290460/nsombo/g3_dawccv.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290459/nsombo/g10_hb8ssj.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290457/nsombo/g9_wstfnb.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290426/nsombo/g1_rsrrhp.jpg" alt=""/>
        </center>
        <p></p>
        </div>
        </Sidebar>
        </Wrapper>
        <Footer1 />
        <Footer />
        </div>
    )
}

export default Success
