import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import Navbar from './Navbar';
import Footer from './Footer/index';
import Footer1 from './Footer1';


const Gallery = () => {
  return (
    <SRLWrapper>
        <Navbar/>
        <p></p>
        <center><h1>***********************</h1></center>
        <p></p>
        <center style={{marginTop: 20}}>
        <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322596/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_10.39.31_PM_tfwwzg.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322596/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_10.39.31_PM_tfwwzg.jpg" alt="Umbrella" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322598/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_10.39.28_PM_vtt3n3.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322598/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_10.39.28_PM_vtt3n3.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322606/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_11.14.45_PM_1_qtwtgs.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322606/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_11.14.45_PM_1_qtwtgs.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322622/nsombo%20new%20photos/WhatsApp_Image_2023-01-13_at_9.55.05_PM_1_wlyspd.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322622/nsombo%20new%20photos/WhatsApp_Image_2023-01-13_at_9.55.05_PM_1_wlyspd.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322639/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.58_AM_1_crtswi.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322639/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.58_AM_1_crtswi.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322638/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.54_AM_hxz8b4.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322638/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.54_AM_hxz8b4.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322636/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.33.00_AM_1_vkvznl.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322636/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.33.00_AM_1_vkvznl.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322636/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.33.01_AM_lgfkp2.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322636/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.33.01_AM_lgfkp2.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322637/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-26_at_10.05.28_AM_i4ylfs.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322637/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-26_at_10.05.28_AM_i4ylfs.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322635/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.33.01_AM_1_xdizgc.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322635/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.33.01_AM_1_xdizgc.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322634/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.58_AM_vcqb3b.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322634/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.58_AM_vcqb3b.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322631/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.59_AM_smyjdz.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322631/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.59_AM_smyjdz.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322634/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.58_AM_vcqb3b.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322634/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.58_AM_vcqb3b.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322635/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.56_AM_1_y5srr3.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322635/nsombo%20new%20photos/New%20Photos%202/WhatsApp_Image_2023-01-22_at_12.32.56_AM_1_y5srr3.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322628/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_10.39.32_PM_xodqu0.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322628/nsombo%20new%20photos/WhatsApp_Image_2023-01-07_at_10.39.32_PM_xodqu0.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322622/nsombo%20new%20photos/WhatsApp_Image_2023-01-13_at_9.55.05_PM_1_wlyspd.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1675322622/nsombo%20new%20photos/WhatsApp_Image_2023-01-13_at_9.55.05_PM_1_wlyspd.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1676449085/WhatsApp_Image_2023-01-26_at_10.05.28_AM_1_dujtcs.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1676449085/WhatsApp_Image_2023-01-26_at_10.05.28_AM_1_dujtcs.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      <a href="https://res.cloudinary.com/dedi0cwbv/image/upload/v1676449063/WhatsApp_Image_2023-01-26_at_10.05.27_AM_1_mv2kvt.jpg" style={{margin: 10}}>
        <img src="https://res.cloudinary.com/dedi0cwbv/image/upload/v1676449063/WhatsApp_Image_2023-01-26_at_10.05.27_AM_1_mv2kvt.jpg" alt="Blue sky" width={300} height={300}/>
      </a>
      
      </center>
      <Footer1 />
      <Footer />
    
    </SRLWrapper>
  )
}

export default Gallery