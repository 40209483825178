import React from 'react';
import Navbar from './Navbar';
import styled from 'styled-components';
import Footer from './Footer/index';
import Footer1 from './Footer1';

const MainContent = styled.div`
background-color: #f7f8fa;
width: 100%;
height: auto;
`;
const Subcontent = styled.div`
display: grid;
grid-template-columns: 50% 50%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

const Sidebar = styled.div`
background-color: #fffdf5;
`;
const Wrapper = styled.div`
margin:1rem auto;
width: min(90%, 75rem);
display: grid;
grid-template-columns: 66% 33%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;


const About = () => {
    return (
        <div>
            <div className="App"
      style={{
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(https://res.cloudinary.com/tambogift/image/upload/v1629067141/nsombo/1920x540dv_fh4c8h.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
            <div><Navbar/></div>
       </div>    
        <Wrapper>               
        <MainContent>
        <p><h3><center>****About Us****</center></h3></p>

<p><h3>NSOMBO INVESTMENTS ZAMBIA LIMITED</h3>
<p>NSOMBO INVESTMENTS ZAMBIA LIMITED is registered as a Business Company by the Registration of Business name Act (Cap 389 of the Laws of Zambia) in the Republic of Zambia and PACRA with Registration number 320160014463. NSOMBO INVESTMENTS ZAMBIA LIMITED has been in existence for over 5 years since 2016.

</p>
</p>

<h4>COMPANY INFOMATION</h4>
<p><strong>TPIN:</strong> 1003998607</p>
<p><strong>REG.NO:</strong> 320160014463</p>
<p><strong>Registration Date:</strong> 22nd December 2016</p>
<p></p>
<p><strong>Website:</strong> www.nsombo.com</p>
<p><strong>Email:</strong> info@nsombo.com</p>
<p><strong>Marketing/ Sales:</strong> marketing@nsombo.com/sales@nsombo.com</p>
<p><strong>Administration:</strong> administration@nsombo.com</p>
<p><strong>Contact:</strong> +61406171344, /+61483003546 / 0977823703/ 0962993107/ 0975896181</p>

<span>By choosing NSOMBO INVESTMENTS ZAMBIA LIMITED, you have selected a reliable Organization to handle your Engineering services (civil, mechanical and construction services). We are driven by a culture of intergrated reform for improved efficiency and effective progression.</span>
<p></p>
<hr/>
<center><h3>MANAGEMENT CONTACT DETAILS</h3></center>
<Subcontent>
<div>

<h4>Nsombo Ignatius</h4>
CHIEF EXECUTIVE OFFICER (C.E.O)
<p>Email: ceo@nsombo.com</p>
<p>Business Contact: +61406171344</p>
<h4>Bwalya Joseph</h4>
MANAGING DIRECTOR (M.D)
<p>Email: md@nsombo.com</p>
Business Contact: +61483003546
<h4>Kaiche Fidelis</h4>
PROJECT & ADMINISTRATION MANAGER
<p>Email: projects@nsombo.com</p>
<p>Business Contact: +260962993107</p>
</div>
<div><h4>Mukwala Aaliyah</h4>
MARKETING & SALES MANAGER
<p>Email: marketing@nsombo.com</p>
<p>Business Contact: +260975896181</p>
<h4>Nsombo Mwewa Doris</h4>
OCCUPATION HEALTH & SAFETY (OHS) MANAGER
<p>Email: ohs@nsombo.com</p>
<p>Business Contact: +260977823703</p>
</div>
</Subcontent>
<p></p>
<hr/>
<center><h3>Certificate of Registration</h3></center>
<p></p>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1655019133/nsombo/Nsombo_Investments_Company_Incorporation_np7csi.jpg" alt=""/>
        </center>
        <p></p>
</MainContent>
        <Sidebar>
        <div>
            <p></p>
        <center><h4><bold>Download Company Profile</bold></h4></center>
        <p></p>        
              <a title=" " href="https://www.dropbox.com/s/gzl4b57gaj8dlvt/Nsombo%20Investments%20Company%20Pofile.pdf?dl=0">
                <center>Company Profile</center>
              </a>
        <p></p><hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290458/nsombo/g4_fq2gdf.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290460/nsombo/g12_etixpa.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067187/nsombo/f3_w5bq3q.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290464/nsombo/g7_uh8eia.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290460/nsombo/g3_dawccv.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290459/nsombo/g10_hb8ssj.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290457/nsombo/g9_wstfnb.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290426/nsombo/g1_rsrrhp.jpg" alt=""/>
        </center>
        <p></p>
        </div>
        </Sidebar>
        </Wrapper>
        <Footer1 />
        <Footer />
        </div>
    )
}

export default About
