import React, { useState } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MenuIcon from "@material-ui/icons/Menu";
import Fade from "react-reveal/Fade";
import CloseIcon from "@material-ui/icons/Close";


const NavbarContainera = styled.div`
width: 100%;
height: 80px;
z-index: 20;
`;

const NavbarWrap = styled.div`
width: auto;
height: 100%;
margin: 0 auto;
z-index: 20;
display: flex;
position: relative;
justify-content: space-between;
@media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.div`
width: 130px;
height: 120px;
background: url('https://res.cloudinary.com/tambogift/image/upload/v1655010361/nsombo/new_logo_wumtqn.jpg') 0 45% no-repeat;
background-size: 100% 100%;
cursor: pointer;
margin-top: 10px;
margin-left: 20px;
margin-right: 250px;
justify-content: start;
`;

const Nav = styled.nav`
flex: 1;
position: relative;
padding-left: 50px;
`;
const NavLink = styled(Link)`
color: #fff;
padding: 0 15px;
font-size: 13px;
line-height: 80px;
font-weight: 700;
text-decoration: none;
justify-content: end;
&:hover {
    color: #f5f51b;}
`;
const Search = styled.div`
right: 1px;
position: absolute;
`;
const SearchWrap = styled.div`
width: 230px;
height: 36px;
position: relative;
box-sizing: border-box; 
background: #fff;
line-height: 33px;
padding-left: 37px;
border-radius: 19px;
color: #fff;

`;
const Input = styled.input`
color: #000;
width: 180px;
border: orange;
font-size: 14px;
background: #fff;
outline: none;

&::placeholder {
    color: ##000;
}
`;
 
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "black" : "white"};
  color: ${props => props.primary ? "white" : "orange"};

  font-size: 1em;
  margin: 0em;
  border-radius: 12px;
  padding: 0.25em 1em;
  border: 2px solid black;
  cursor: pointer;
  &:focus {
    color: #0d0d0d;
  }
  &:hover {
    color: #0d0d0d;
    transform: scale(1.05);
  }
`;

const RightMenu = styled.div`
text-transform: uppercase;
display: flex;
align-items: center;
flex-direction: column;
align-items: start;
margin-bottom: 0px;
margin-Top: 0px;
margin-left: 10px;
padding: 20px;
position: fixed;
background-color: black;
transform: ${(props) => (props.show ? "translateX(0)" : "translateX(500%)")};
transition: transform 0.2s;
width: 250px;
height: 320px;
z-index: 16;
`;

const NavLink2 = styled(Link)`
  margin-right: 10px;
  padding: 2px;
  color: #fff;
  flex-wrap: nowrap;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: #f5f51b;
    font-size: 17px;
  }
`;
const CustomMenu = styled(MenuIcon)`
  padding: 20px;
  scale: 200%;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #f5f51b;
    transform: scale(120%);
  };
  
`;

const CustomClose = styled(CloseIcon)`
  color: #f5ed07;
  cursor: pointer;
`;

const CloseWraper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 230px;
`;

const Mobile = styled.div`
@media (min-width: 768px) {
    display: none;
  };
`;


const Navbar = () => {

    const [burgerStatus, setBurgerStatus] = useState(false);

    return (
        <NavbarContainera>
            <NavbarWrap>
                <Logo />
                <Nav>
                    <NavLink to="/"><Button>HOME</Button></NavLink>
                    <NavLink to="/About"><Button>ABOUT US</Button></NavLink>
                    <NavLink to="/Company"><Button>COMPANY INFO</Button></NavLink>
                    <NavLink to="/Contact"><Button>CONTACT US</Button></NavLink>
                    <NavLink to="/Services"><Button>OUR SERVICES AND PRODUCT</Button></NavLink>                       
                    <NavLink to="/Success"><Button>KEY SUCCESS</Button></NavLink>
                    <NavLink to="/News"><Button>NEWS</Button></NavLink>
                    <NavLink to="/Management"><Button>MANAGEMENT</Button></NavLink>
                    <NavLink to="/Gallery"><Button>GALLERY</Button></NavLink>                 
                </Nav>
                <Search>
                    
                </Search>
                
            </NavbarWrap>
            <Mobile>
        <Fade left>
          <CustomMenu onClick={() => setBurgerStatus(true)} />

          <RightMenu show={burgerStatus}>
            <CloseWraper>
              <CustomClose onClick={() => setBurgerStatus(false)} />
            </CloseWraper>
                    <NavLink2 to="/"><Button>HOME</Button></NavLink2>
                    <NavLink2 to="/About"><Button>ABOUT US</Button></NavLink2>
                    <NavLink2 to="/Company"><Button>COMPANY INFO</Button></NavLink2>
                    <NavLink2 to="/Contact"><Button>CONTACT US</Button></NavLink2>
                    <NavLink2 to="/Services"><Button>OUR SERVICES AND PRODUCT</Button></NavLink2>                       
                    <NavLink2 to="/Success"><Button>KEY SUCCESS</Button></NavLink2>
                    <NavLink2 to="/News"><Button>NEWS</Button></NavLink2>
                    <NavLink2 to="/Management"><Button>MANAGEMENT</Button></NavLink2>
          </RightMenu>
        </Fade>
        </Mobile>
        </NavbarContainera>
    )
}

export default Navbar
