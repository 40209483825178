import React from 'react';
import Navbar from './Navbar';
import styled from 'styled-components';
import Footer from './Footer/index';
import Footer1 from './Footer1';


const MainContent = styled.div`
background-color: #f7f8fa;

`;
const Subcontent = styled.div`
display: grid;
grid-template-columns: 50% 50%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

const Sidebar = styled.div`
background-color: #fffdf5;
`;
const Wrapper = styled.div`
margin:1rem auto;
width: min(90%, 75rem);
display: grid;
grid-template-columns: 66% 33%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;


const Management = () => {
    return (
        <div>
            <div className="App"
      style={{
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(https://res.cloudinary.com/tambogift/image/upload/v1629483411/Fancydone_3_1920x540_bwkjsz.png)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <div><Navbar/></div>
       </div>    
        <Wrapper>               
        <MainContent>
        <p><h3><center>****Management****</center></h3></p>


<center><h3>MANAGEMENT CONTACT DETAILS</h3></center>
<Subcontent>
<div>

<h4>Nsombo Ignatius</h4>
CHIEF EXECUTIVE OFFICER (C.E.O)
<p>Email: ceo@nsombo.com</p>
<p>Address: 5766, Pine Groove, Ndola</p>
<p>Business Contact: +61406171344</p>

<h4>Siame Memory</h4>
MANAGING DIRECTOR (M.D)
<p>Email: info@nsombo.com/
administration@nsombo.com</p>
Business Contact: +260764000474

<h4>Mwape Kaiche</h4>
PROJECT MANAGER
<p>Email: projects@nsombo.com/
nsombo@nsombo.com /</p>
<p>Business Contact: +260950878881</p>
</div>

<div>

<h4>Kapeya Raphael</h4>
Acting/ MARKETING & SALES MANAGER
<p>Email: marketing@nsombo.com/
info@nsombo.com</p>
<p>Business Contact: +260978639472</p>

<h4>Nsombo Darius</h4>
Purchase & Procumbent Manger
<p>Email: procurement@nsombo.com</p>
<p>Business Contact: +260968875141/ 0763405102</p>

<h4>Kapeya Raphael</h4>
REGIONAL MANAGER-COPPERBELT
<p>Address: 5766, Pine Groove, Ndola</p>
<p>Email: info@nsombo.com</p>
<p>Business Contact: +260978639472</p>
</div>
</Subcontent>
<p></p>
<hr/>
<p></p>
<p></p>
</MainContent>
        <Sidebar>
        <div>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290458/nsombo/g4_fq2gdf.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290460/nsombo/g12_etixpa.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067187/nsombo/f3_w5bq3q.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290464/nsombo/g7_uh8eia.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290460/nsombo/g3_dawccv.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290459/nsombo/g10_hb8ssj.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290457/nsombo/g9_wstfnb.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290426/nsombo/g1_rsrrhp.jpg" alt=""/>
        </center>
        <p></p>
        </div>
        </Sidebar>
        </Wrapper>
        <Footer1 />
        <Footer />
        </div>
    )
}

export default Management
