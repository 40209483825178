import React from 'react'
import Navbar from './Navbar'
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import './ContactUs.css';
import Footer from './Footer/index';
import Footer1 from './Footer1';

const MainContent = styled.div`
background-color: #f7f8fa;

`;
const Subcontent = styled.div`
display: grid;
grid-template-columns: 50% 50%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

const Sidebar = styled.div`
background-color: #fffdf5;
`;
const Wrapper = styled.div`
margin:1rem auto;
width: min(90%, 75rem);
display: grid;
grid-template-columns: 66% 33%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_3ekeil2', 'template_bx5p6oq', e.target, 'user_q4acIgzRzdabFIcsIRMIR')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

const Contact = () => {
    return (
        <div>
            <div className="App"
      style={{
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(https://res.cloudinary.com/tambogift/image/upload/v1629067142/nsombo/1920x540dvii_sr4e06.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <div><Navbar/></div>
       </div>    
       <Wrapper>               
        <MainContent>
        <p><h3><center>****Contact Us****</center></h3></p>

<p><h4>NSOMBO INVESTMENTS ZAMBIA LIMITED Contact Form</h4>
<p>Kindly fill in the Form below and click on the send button to send it over to Us.

</p>
</p>

<form onSubmit={sendEmail}>
      <div class="pageTitle title">Contact Us </div>
      <div class="secondaryTitle title">Please fill this form to contact us.</div>
      <input type="hidden" name="contact_number"/>
      <label className="secondaryTitle title">Type Your Name</label>
      <input type="text" name="user_name" className="email formEntry" placeholder="Name"/>
      <label className="secondaryTitle title">Your Email Address</label>
      <input type="email" name="user_email" className="email formEntry" placeholder="Email"/>
      <label className="secondaryTitle title">Type your Message</label>
      <textarea name="message" className="message formEntry" placeholder="Message"></textarea>
      <input type="submit" value="Send" className="submit formEntry"/>
</form>

<p></p>
<hr/>
<center><h3>MANAGEMENT CONTACT DETAILS</h3></center>
<Subcontent>
<div>

<h4>Nsombo Ignatius</h4>
CHIEF EXECUTIVE OFFICER (C.E.O)
<p>Email: ceo@nsombo.com</p>
<p>Address: 5766, Pine Groove, Ndola</p>
<p>Business Contact: +61406171344</p>

<h4>Siame Memory</h4>
MANAGING DIRECTOR (M.D)
<p>Email: info@nsombo.com/
administration@nsombo.com</p>
Business Contact: +260764000474

<h4>Mwape Kaiche</h4>
PROJECT MANAGER
<p>Email: projects@nsombo.com/
nsombo@nsombo.com /</p>
<p>Business Contact: +260950878881</p>
</div>

<div>

<h4>Kapeya Raphael</h4>
Acting/ MARKETING & SALES MANAGER
<p>Email: marketing@nsombo.com/
info@nsombo.com</p>
<p>Business Contact: +260978639472</p>

<h4>Nsombo Darius</h4>
Purchase & Procumbent Manger
<p>Email: procurement@nsombo.com</p>
<p>Business Contact: +260968875141/ 0763405102</p>

<h4>Kapeya Raphael</h4>
REGIONAL MANAGER-COPPERBELT
<p>Address: 5766, Pine Groove, Ndola</p>
<p>Email: info@nsombo.com</p>
<p>Business Contact: +260978639472</p>

</div>
</Subcontent>
<p></p>
<hr/>
<center><h3></h3></center>
<p></p>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067186/nsombo/e2_xdqjx6.png" alt=""/>
        </center>
        <p></p>
</MainContent>
        <Sidebar>
        <div>
        <p></p>
        <center><h4><bold>Download Company Profile</bold></h4></center>
        <p></p>        
              <a title=" " href="https://www.dropbox.com/s/p0m6b56irtxhhao/new%20nsombo%20company%20pofile.pdf?dl=0">
                <center>Company Profile</center>
              </a>
        <p></p><hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067185/nsombo/f1_shaof7.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290459/nsombo/g10_hb8ssj.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067183/nsombo/f2_ygmuy0.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290464/nsombo/g7_uh8eia.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290426/nsombo/g1_rsrrhp.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067185/nsombo/e1_g7ceo0.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629290457/nsombo/g9_wstfnb.png" alt=""/>
        </center>
        <p></p>
        </div>
        </Sidebar>
        </Wrapper>
        <Footer1 />
        <Footer />
        </div>
    )
}

export default Contact
