import React from 'react';
import profile from './images/ashutosh.png';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

const Container = styled(animated.div)`
display: inline-block;
padding: 3em;
background: #C7D2FE66;
border-radius: 10px;
z-index: 1;
position: relative;
backdrop-filter: blur(10px);
border: 2px solid transparent;
background-clip: border-box;
cursor: pointer;
margin-top: 130px;
@media (max-width: 768px) {
    margin-top: 230px;
  }
`;

const StyledImg = styled.img`
    width: 200px;
    height: auto;
    border-radius: 50%;
`;

const StyledH1 = styled.h1`
    line-heright: 1.5;
    letter-spacing: 1.5;
    font-family: "Gilroy";
    font-size: 40px;
    font-color: black;
`;

const StyledH3 = styled.h3`
    line-heright: 1.5;
    letter-spacing: 1.15;
    font-family: "Gilroy";
    font-size: 25px;
    font-color: black;
`;

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const GlassCard = () => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1] , config: config.default}))
    return (
        <Container
            onMouseMove={({clientX: x, clientY: y}) => (set({xys: calc(x, y)}))}
            onMouseLeave={() => set({xys:[0,0,1]})}
            style={{
                transform: props.xys.interpolate(trans)
            }}
        >
            <StyledImg src="https://res.cloudinary.com/tambogift/image/upload/v1655010333/nsombo/Logo_PNG_TP_2_ft2wwk.png" />
            <StyledH1>NSOMBO</StyledH1>
            <StyledH3>Mining, Civil, Mechanical and Construction Engineering Services <br/> in Zambia</StyledH3>
        </Container>
    );
}

export default GlassCard;