import React from 'react'
import Navbar from './Navbar'
import styled from 'styled-components';
import Footer from './Footer/index';
import Footer1 from './Footer1';

const MainContent = styled.div`
background-color: #f7f8fa;

`;
const Subcontent = styled.div`
display: grid;
grid-template-columns: 50% 50%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

const Sidebar = styled.div`
background-color: #fffdf5;
`;
const Wrapper = styled.div`
margin:1rem auto;
width: min(90%, 75rem);
display: grid;
grid-template-columns: 66% 33%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;


const Services = () => {
    return (
        <div>
            <div className="App"
      style={{
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(https://res.cloudinary.com/tambogift/image/upload/v1629067140/nsombo/1920x540dvi_mpwvvr.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <div><Navbar/></div>
       </div>    
       <Wrapper>               
        <MainContent>
        <p><h3><center>****Services****</center></h3></p>

<p><h3>KEY TO SUCCESS</h3>
<p>Currently, with the high demand for Civil Engineering services, Mechanical Engineering services, Construction services and other sectors, we, Nsombo Investments Zambia Limited therefore aim for excellence in fulfilling our promise of credible service delivery, thereby developing undoubted confidence in our clients, we provide easy access for our clients and great visibility for potential clients. With valuable experience in the field, we provide customer satisfaction and develop loyalty in our clients by providing reasonable pricing, free expert advice , fast and detailed quotations and the ability to handle and resolve queries.
</p>
</p>

<p></p>
<hr/>
<center><h3>
PRODUCT LINE AND SERVICES
</h3>
</center>
<center>We are specialized in the following products and services:</center>
<Subcontent>
<div>
<h4>CORE PRODUCT LINE AND SERVICES</h4>    
<p>1. Civil Engineering works</p>
<p>2. Mechanical Engineering works</p>
<p>3. Transport works and Services</p>
<p>4. Construction and building Services</p>
<p>5. Continuous mining equipment</p>
<p>6. General Goods and services</p>
<p>7. Underground mining services</p>
<p>8. Shaft mining services</p>
<p>9. Open pit mining services</p>
<p>10. Maintenance of Mining ma-chines (Heavy equipment and light duty maintenance)</p>
<p>11. Shut down repairs</p>
<p>12. Labour hire</p>
<p>13. Supply of special tools</p>
</div>
<div><h4>OUR OTHER SPECIALITY</h4>
<p>1. Shaft mining services and Open pit mining services</p>
<p>2. Hydraulic services (rebuild and supply of hydraulic components)</p>
<p>3. Underground mining service (vehicles)</p>
<p>4. Non electrical lighting devices</p>
<p>5. Electrical wire</p>
<p>6. Road Maintenance Works</p>
<p>7. All kinds of Civil works and Earth works</p>
<p>8. Design and Build Contracts</p>
<p>9. General maintenance of all kinds of building</p>
<p>10. Supply personal protective equipment (PPE)</p>
<p>11. Offering of technical support, training and assessment</p>

</div>
</Subcontent>
<p></p>
<hr/>
<p></p>
<center><img src="https://res.cloudinary.com/tambogift/image/upload/v1629067185/nsombo/e1_g7ceo0.png" alt=""/></center>
</MainContent>
        <Sidebar>
        <div>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067185/nsombo/f1_shaof7.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067188/nsombo/e4_x5t6jz.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067187/nsombo/f3_w5bq3q.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067188/nsombo/e3_szf0wv.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067183/nsombo/f2_ygmuy0.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067183/nsombo/f2_ygmuy0.jpg" alt=""/>
        </center>
        <p></p>
        
        </div>
        </Sidebar>  
        </Wrapper>
        <Footer1 />
        <Footer />
        </div>
    )
}

export default Services
