import logo from './logo.svg';
import './App.css';
import background from './images/background2.jpg';
import GlassCard from './glasscard';
import NavbarT from './components/NavbarT';
import Footer from './components/Footer/index';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Footer1 from './components/Footer1';

const GlassLink = styled(Link)`
width: 100%;
height: auto;
color: #080808;
  text-decoration: none;
  &:hover {
    color: #f5f51b;}
    `;

function App() {
  return (
    <div>
      
      <main>
        <section>
    <div className="App"
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url("https://res.cloudinary.com/tambogift/image/upload/v1629290596/nsombo/background2_rkdieh.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <header>
      <NavbarT/> 
      </header>
      <section>
      <GlassLink to="/Services"><GlassCard /></GlassLink>
      </section>
    </div>
    </section>
    <section>
     <Footer1 />
     <Footer />
    </section>
    </main>
    </div>
  );
}

export default App;
