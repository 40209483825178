import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const NavbarContainera = styled.div`
width: 100%;
height: 80px;
z-index: 20;
background-color: #0d0909;
`;

const NavbarWrap = styled.div`
width: 1200px;
height: 100%;
margin: 0 auto;
z-index: 20;
display: flex;
position: relative;
align-items: center;
justify-content: space-between;
@media(max-width: 768px) {
    display: none;
}
`;

const Logo = styled.div`
width: 130px;
height: 120px;
background: url('') 0 45% no-repeat;
background-size: 100% 100%;
cursor: pointer;
margin-top: 80px
`;

const Nav = styled.nav`
flex: 1;
position: relative;
padding-left: 50px;
`;
const NavLink = styled(Link)`
color: #fff;
padding: 0 15px;
font-size: 13px;
line-height: 80px;
font-weight: 200;
text-decoration: none;
&:hover {
    color: #f5f51b;
`;
const Search = styled.div`
right: 1px;
position: absolute;
`;
const SearchWrap = styled.div`
width: 230px;
height: 36px;
position: relative;
box-sizing: border-box; 
background: #fff;
line-height: 33px;
padding-left: 37px;
border-radius: 19px;
color: #fff;

`;
const Input = styled.input`
color: #000;
width: 180px;
border: orange;
font-size: 14px;
background: #fff;
outline: none;

&::placeholder {
    color: ##000;
}
`;


const Footer1 = () => {
    return (
        <NavbarContainera>
            <NavbarWrap>
                <Logo />
                <Nav>
                    <NavLink to="/">HOME</NavLink>
                    <NavLink to="/About">ABOUT US</NavLink>
                    <NavLink to="/Company">COMPANY INFO</NavLink>
                    <NavLink to="/Contact">CONTACT US</NavLink>
                    <NavLink to="/Services">OUR SERVICES AND PRODUCT</NavLink>                       
                    <NavLink to="/Success">KEY SUCCESS</NavLink>
                    <NavLink to="/News">NEWS</NavLink>
                    <NavLink to="/Management">MANAGEMENT</NavLink>
                    <NavLink to="/Gallery">GALLERY</NavLink>                
                </Nav>
                <Search>
                    
                </Search>
                
            </NavbarWrap>
            
        </NavbarContainera>
    )
}

export default Footer1
