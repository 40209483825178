import React from 'react'
import Navbar from './Navbar'
import styled from 'styled-components';
import Footer from './Footer/index';
import Footer1 from './Footer1';

const MainContent = styled.div`
background-color: #f7f8fa;

`;
const Subcontent = styled.div`
display: grid;
grid-template-columns: 50% 50%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

const Sidebar = styled.div`
background-color: #fffdf5;
`;
const Wrapper = styled.div`
margin:1rem auto;
width: min(90%, 75rem);
display: grid;
grid-template-columns: 66% 33%;
gap: 1rem;
@media only screen and (max-width: 550px) {
display: grid;
grid-template-columns: 100%;
gap: 1rem;
}
`;

const News = () => {
    return (
        <div>
            <div className="App"
      style={{
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(https://res.cloudinary.com/tambogift/image/upload/v1629067155/nsombo/1920x540iii_yn3zkn.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <div><Navbar/></div>
       </div>    
       <Wrapper>               
        <MainContent>
        <p><h3><center>****News****</center></h3></p>

<center>This is the News Only Page</center>
<Subcontent>


</Subcontent>
<p></p>
<hr/>
<p></p>
<center><img src="https://res.cloudinary.com/tambogift/image/upload/v1629067185/nsombo/e1_g7ceo0.png" alt=""/></center>
</MainContent>
        <Sidebar>
        <div>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067185/nsombo/f1_shaof7.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067188/nsombo/e4_x5t6jz.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067187/nsombo/f3_w5bq3q.jpg" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067188/nsombo/e3_szf0wv.png" alt=""/>
        </center>
        <p></p>
        <p></p>
        <hr/>
        <p></p>
        <center>
        <img width="70%" height="70%" src="https://res.cloudinary.com/tambogift/image/upload/v1629067183/nsombo/f2_ygmuy0.jpg" alt=""/>
        </center>
        <p></p>
        </div>
        </Sidebar>         
        </Wrapper>
        <Footer1 />
        <Footer />
        </div>
    )
}

export default News
